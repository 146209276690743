// Do not edit directly
// File generated from gatsby-node.js

export default {
      '/nl/producten/pura': '/nl/producten/pura',
      '/nl/producten/verbindingsbeugel-25-uni': '/nl/producten/verbindingsbeugel-25-uni',
      '/nl/producten/houten-lateihaak-uni': '/nl/producten/houten-lateihaak-uni',
      '/nl/producten/muurbevestigingssteun-25-uni': '/nl/producten/muurbevestigingssteun-25-uni',
      '/nl/producten/aluminium-rail-uni': '/nl/producten/aluminium-rail-uni',
      '/nl/producten/geanodiseerde-aluminium-latei-met-eindkappen-45-mm-zwart': '/nl/producten/geanodiseerde-aluminium-latei-met-eindkappen-45-mm-zwart',
      '/nl/producten/geanodiseerde-aluminium-latei-met-eindkappen-25-mm-zwart': '/nl/producten/geanodiseerde-aluminium-latei-met-eindkappen-25-mm-zwart',
      '/nl/producten/plat-aluminium-latei-zwart': '/nl/producten/plat-aluminium-latei-zwart',
      '/nl/producten/handgreep-58-mm-zwart': '/nl/producten/handgreep-58-mm-zwart',
      '/nl/producten/ondergeleider-voor-systemen-g80-g100': '/nl/producten/ondergeleider-voor-systemen-g80-g100',
      '/nl/producten/zachte-sluitdemper-voor-s120-g100': '/nl/producten/zachte-sluitdemper-voor-s120-g100',
      '/nl/producten/zachte-sluitdemper-voor-s60-s100': '/nl/producten/zachte-sluitdemper-voor-s60-s100',
      '/nl/producten/w40-sliding-system-kit-for-2-additional-door-leaves': '/nl/producten/w40-sliding-system-kit-for-2-additional-door-leaves',
      '/nl/producten/w40-sliding-system-kit-for-1-additional-door-leaf': '/nl/producten/w40-sliding-system-kit-for-1-additional-door-leaf',
      '/nl/producten/l30-sliding-system-kit-for-additional-door-leaf': '/nl/producten/l30-sliding-system-kit-for-additional-door-leaf',
      '/nl/producten/l40-sliding-system-kit-for-additional-door-leaf': '/nl/producten/l40-sliding-system-kit-for-additional-door-leaf',
      '/nl/producten/soft-close-damper-w40': '/nl/producten/soft-close-damper-w40',
      '/nl/producten/w60-sliding-system-kit-for-2-additional-door-leaves': '/nl/producten/w60-sliding-system-kit-for-2-additional-door-leaves',
      '/nl/producten/w60-sliding-system-kit-for-1-additional-door-leaf': '/nl/producten/w60-sliding-system-kit-for-1-additional-door-leaf',
      '/nl/producten/l30-system-for-lightweight-sliding-doors': '/nl/producten/l30-system-for-lightweight-sliding-doors',
      '/nl/producten/l40-system-for-lightweight-sliding-doors': '/nl/producten/l40-system-for-lightweight-sliding-doors',
      '/nl/producten/loft-karo-zwart-schuifdeursysteem': '/nl/producten/loft-karo-zwart-schuifdeursysteem',
      '/nl/producten/loft-u-zwart-schuifdeursysteem': '/nl/producten/loft-u-zwart-schuifdeursysteem',
      '/nl/producten/ondergeleider-voor-systemen-s60-s100-s120': '/nl/producten/ondergeleider-voor-systemen-s60-s100-s120',
      '/nl/producten/plat-aluminium-latei': '/nl/producten/plat-aluminium-latei',
      '/nl/producten/afstandshuls': '/nl/producten/afstandshuls',
      '/nl/producten/w60-schuifsysteem-voor-kast-kleerkastdeuren': '/nl/producten/w60-schuifsysteem-voor-kast-kleerkastdeuren',
      '/nl/producten/w40-schuifsysteem-voor-kast-kleerkastdeuren': '/nl/producten/w40-schuifsysteem-voor-kast-kleerkastdeuren',
      '/nl/producten/handgreep-schuifdeur-03': '/nl/producten/handgreep-schuifdeur-03',
      '/nl/producten/loft-zachte-sluitdempers-wit': '/nl/producten/loft-zachte-sluitdempers-wit',
      '/nl/producten/loft-zachte-sluitdempers-zwart': '/nl/producten/loft-zachte-sluitdempers-zwart',
      '/nl/producten/loft-v-zwart-schuifdeursysteem': '/nl/producten/loft-v-zwart-schuifdeursysteem',
      '/nl/producten/f40-roller': '/nl/producten/f40-roller',
      '/nl/producten/f40-vouwdeursysteemkit-voor-2-extra-panelen': '/nl/producten/f40-vouwdeursysteemkit-voor-2-extra-panelen',
      '/nl/producten/f25-roller': '/nl/producten/f25-roller',
      '/nl/producten/f25-vouwdeursysteemkit-voor-2-extra-panelen': '/nl/producten/f25-vouwdeursysteemkit-voor-2-extra-panelen',
      '/nl/producten/g100-schuifdeursysteemkit-voor-extra-glazen-deuren': '/nl/producten/g100-schuifdeursysteemkit-voor-extra-glazen-deuren',
      '/nl/producten/g80-schuifdeursysteemkit-voor-extra-glazen-deuren': '/nl/producten/g80-schuifdeursysteemkit-voor-extra-glazen-deuren',
      '/nl/producten/geanodiseerde-aluminium-latei-met-eindkappen-45-mm': '/nl/producten/geanodiseerde-aluminium-latei-met-eindkappen-45-mm',
      '/nl/producten/geanodiseerde-aluminium-latei-met-eindkappen-25-mm': '/nl/producten/geanodiseerde-aluminium-latei-met-eindkappen-25-mm',
      '/nl/producten/aluminium-rail-s': '/nl/producten/aluminium-rail-s',
      '/nl/producten/handgreep-58-mm': '/nl/producten/handgreep-58-mm',
      '/nl/producten/loft-i-zwart-schuifdeursysteem': '/nl/producten/loft-i-zwart-schuifdeursysteem',
      '/nl/producten/g80-schuifdeursysteem-voor-glazen-deuren': '/nl/producten/g80-schuifdeursysteem-voor-glazen-deuren',
      '/nl/producten/g100-schuifdeursysteem-voor-glazen-deuren': '/nl/producten/g100-schuifdeursysteem-voor-glazen-deuren',
      '/nl/producten/f40-vouwdeursysteem-voor-twee-panelen': '/nl/producten/f40-vouwdeursysteem-voor-twee-panelen',
      '/nl/producten/f25-vouwdeursysteem-voor-twee-panelen': '/nl/producten/f25-vouwdeursysteem-voor-twee-panelen',
      '/nl/producten/s100-schuifdeursysteem-voor-twee-deuren': '/nl/producten/s100-schuifdeursysteem-voor-twee-deuren',
      '/nl/producten/s120-schuifdeursysteem-voor-twee-deuren': '/nl/producten/s120-schuifdeursysteem-voor-twee-deuren',
      '/nl/producten/s60-schuifdeursysteem-voor-twee-deuren': '/nl/producten/s60-schuifdeursysteem-voor-twee-deuren',
      '/nl/producten/s120-roller': '/nl/producten/s120-roller',
      '/nl/producten/s100-roller': '/nl/producten/s100-roller',
      '/nl/producten/s60-roller': '/nl/producten/s60-roller',
      '/nl/producten/houten-lateihaak-s': '/nl/producten/houten-lateihaak-s',
      '/nl/producten/verbindingsbeugel-45-s': '/nl/producten/verbindingsbeugel-45-s',
      '/nl/producten/verbindingsbeugel-25-s': '/nl/producten/verbindingsbeugel-25-s',
      '/nl/producten/muurbevestigingssteun-45-s': '/nl/producten/muurbevestigingssteun-45-s',
      '/nl/producten/muurbevestigingssteun-25-s': '/nl/producten/muurbevestigingssteun-25-s',
      '/nl/producten/s100-schuifdeursysteemkit-voor-extra-deuren': '/nl/producten/s100-schuifdeursysteemkit-voor-extra-deuren',
      '/nl/producten/s120-schuifdeursysteemkit-voor-extra-deuren': '/nl/producten/s120-schuifdeursysteemkit-voor-extra-deuren',
      '/nl/producten/s60-schuifdeursysteemkit-voor-extra-deuren': '/nl/producten/s60-schuifdeursysteemkit-voor-extra-deuren',
      '/nl/producten/2s-synchronisatiekit': '/nl/producten/2s-synchronisatiekit',
      '/nl/producten/s100-schuifdeursysteem-voor-een-deur': '/nl/producten/s100-schuifdeursysteem-voor-een-deur',
      '/nl/producten/s120-schuifdeursysteem-voor-een-deur': '/nl/producten/s120-schuifdeursysteem-voor-een-deur',
      '/nl/producten/s60-schuifdeursysteem-voor-een-deur': '/nl/producten/s60-schuifdeursysteem-voor-een-deur',
      '/nl/imprint': '/nl/imprint',
      '/nl/verzending-en-betaling': '/nl/verzending-en-betaling',
      '/nl/data-protection': '/nl/data-protection',
      '/nl/cancellation': '/nl/cancellation',
      '/nl/bedrijf': '/nl/bedrijf',
      '/nl/terms-and-conditions': '/nl/terms-and-conditions',
      '/nl/producten': '/nl/producten',
}
